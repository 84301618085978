import EastIcon from '@mui/icons-material/East';
import { Breakpoint, Container, Typography } from '@mui/material';
import React from 'react';

type OAuthLayoutProps = {
  heading?: string;
  tenantName?: string;
  maxWidth?: false | Breakpoint;
  children: React.ReactNode;
};

function OAuthLayout({ heading, tenantName, maxWidth, children }: OAuthLayoutProps) {
  return (
    <Container maxWidth={maxWidth ?? 'sm'}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
        <div>
          <Typography variant="h1" fontSize={32} fontWeight={600}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>{heading}</div>
              <div>
                <EastIcon />
              </div>
              <div>{tenantName}</div>
            </div>
          </Typography>
          {children}
        </div>
      </div>
    </Container>
  );
}

export default OAuthLayout;
