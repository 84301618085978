import { AxiosResponse } from 'axios';

import { FlybaseBase } from '../lib';

import { ContentsType, ContentData, ReplacementType, Get } from './types';

export function replace(content: string, replacementKey: string, replacementValue: string | number) {
  const search = `{{{${replacementKey.toUpperCase()}}}}`;

  return content.replaceAll(search, replacementValue.toString());
}

export function replaceAll(content: string, replacements?: ReplacementType) {
  let replacedContent = ` ${content}`.slice(1); // forced copy

  if (!replacements) {
    return replacedContent;
  }

  for (const [key, value] of Object.entries(replacements)) {
    replacedContent = replace(replacedContent, key, value);
  }

  return replacedContent;
}

/**
 * Get all contents by a group name, and order them.
 * Groups are determined by "." characters in the machine name.
 *
 * For example: useContentGroup('faq.internetPrivacy'), given the contents:
 *
 * - faq.internetPrivacy.10.question = 'q10'
 * - faq.internetPrivacy.10.answer   = 'a10'
 * - faq.internetPrivacy.20.question = 'q20'
 * - faq.internetPrivacy.20.answer   = 'a20'
 * - faq.internetPrivacy.30.question = 'q30'
 * - faq.internetPrivacy.30.answer   = 'a30'
 *
 * will return the following array (always correctly ordered on the index).
 * result = [
 *    { index: '10', question: 'q10', answer: 'a10' },
 *    { index: '20', question: 'q20', answer: 'a20' },
 *    { index: '30', question: 'q30', answer: 'a30' },
 * ];
 */
export function useContentGroup(
  contents: ContentsType,
  groupName: string,
  { map }: { map?: (machineName: string) => string | JSX.Element | JSX.Element[] } = {}
) {
  const allMachineNames = Object.keys(contents);
  const foundMachineNames = allMachineNames
    .filter((machineName) => machineName.startsWith(`${groupName}.`))
    .map((machineName) => machineName.substring(groupName.length + 1))
    .filter((machineName) => {
      const machineNameParts = machineName.split('.');

      return !Number.isNaN(parseInt(machineNameParts[0], 10));
    });

  const result: {
    [key: string]: string | JSX.Element | JSX.Element[];
    index: string;
  }[] = [];

  foundMachineNames.forEach((machineName) => {
    const machineNameParts = machineName.split('.');
    const intPart = parseInt(machineNameParts[0], 10);

    if (!result[intPart]) {
      result[intPart] = {
        index: machineNameParts[0],
      };
    }

    const name = `${groupName}.${machineName}`;

    if (machineNameParts[1] !== 'index') {
      result[intPart][machineNameParts[1]] = map ? map(name) : name;
    }
  });

  return result.filter((item) => !!item);
}

export default class Content extends FlybaseBase {
  async get() {
    const response = await this.makeRequest<unknown, AxiosResponse<ContentData[]>>('/content/', {
      method: 'GET',
    });

    const contents: ContentsType = Object.fromEntries<ContentsType[string]>(
      response.data.map((contentItem) => {
        const contentPerLocale = Object.fromEntries<ContentsType[string][string]>(
          contentItem.contentFills.map((contentFill) => [contentFill.locale, contentFill.fill]) ?? []
        );

        return [contentItem.machineName, contentPerLocale];
      }) ?? []
    );

    const get: Get = (machineName: string, locale: string, { replacements } = {}) => {
      const value = contents?.[machineName]?.[locale];

      if (typeof value !== 'string') {
        this.makeRequest('/content/missing', {
          method: 'POST',
          data: {
            machineName,
            locale,
          },
        })
          .then(({ data }) => {
            // eslint-disable-next-line no-console
            console.log(`Successfully reported missing content value (${machineName}, ${locale}): ${data}`);
          })
          .catch(console.error);

        return machineName;
      }

      return replacements ? replaceAll(value, replacements) : value;
    };

    return {
      contents,
      get,
    };
  }
}
