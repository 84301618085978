import { CircularProgress } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import ErrorBoundary from '@/components/shared/ErrorBoundary';
import { setTenant, useUserStore } from '@/stores/UserStore';

type ElementChildrenProp = {
  children: React.ReactNode;
};

function LoadingPage() {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '100vw',
      }}
    >
      <CircularProgress />
    </div>
  );
}

export function Public({ children }: ElementChildrenProp) {
  return <ErrorBoundary>{children}</ErrorBoundary>;
}

export function NotAuth({ children }: ElementChildrenProp) {
  const { isAuthenticated } = useUserStore();
  const location = useLocation();

  const from = (location.state as any)?.from || '/';

  const toRender = () => {
    if (isAuthenticated === null) {
      return <LoadingPage />;
    }

    if (isAuthenticated) {
      return <Navigate replace to={from} />;
    }

    return children;
  };

  return <ErrorBoundary>{toRender()}</ErrorBoundary>;
}

export function RequireAuth({ children }: ElementChildrenProp) {
  const { isAuthenticated } = useUserStore();
  const location = useLocation();

  const toRender = () => {
    if (isAuthenticated === null) {
      return <LoadingPage />;
    }

    if (isAuthenticated) {
      return children;
    }

    return <Navigate replace to="/login" state={{ from: location?.pathname }} />;
  };

  return <ErrorBoundary>{toRender()}</ErrorBoundary>;
}

export function RequireTenant({ children }: ElementChildrenProp) {
  const user = useUserStore();
  const location = useLocation();
  const isFetching = useRef(false);

  const toRender = () => {
    if (user.isAuthenticated === null) {
      return <LoadingPage />;
    }

    if (!user.isAuthenticated) {
      return <Navigate replace to="/login" state={{ from: location?.pathname }} />;
    }

    // Admin dashboard will prompt user to create a tenant if user has 0 tenants
    if (user.tenants.length === 0) {
      return <Navigate replace to="/admin/dashboard" state={{ from: location?.pathname }} />;
    }

    if (!user.currentTenant) {
      if (user.tenants.length > 1) {
        return <Navigate replace to="/switch" state={{ from: location?.pathname }} />;
      }

      return <LoadingPage />;
    }

    return children;
  };

  useEffect(() => {
    if (user?.isAuthenticated && !isFetching.current && user?.tenants && !user.currentTenant && user.tenants.length <= 1) {
      isFetching.current = true;
      setTenant(user.tenants[0])
        .catch(console.error)
        .finally(() => {
          isFetching.current = false;
        });
    }
  }, [user]);

  return <ErrorBoundary>{toRender()}</ErrorBoundary>;
}
