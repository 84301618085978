import TwoFARequired from '@boilerplate/components/app/Profile/TwoFactor/TwoFARequired';
import LoggedInCallbackPage from '@boilerplate/components/auth/DefaultLoggedInCallbackPage';
import { NoSsr } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { Fragment, lazy, Suspense } from 'react';
import { Route, Routes as RouterRoutes } from 'react-router-dom';

import ForgotPasswordPage from '@/components/auth/ForgotPasswordPage';
import LoginPage from '@/components/auth/LoginPage';
import RegisterPage from '@/components/auth/RegisterPage';
import ResetPasswordPage from '@/components/auth/ResetPasswordPage';
import SwitchTenantPage from '@/components/auth/SwitchTenantPage';
import OAuthConfirmAccountPage from '@/components/oauth/OAuthConfirmAccountPage';
import OAuthForgotPasswordPage from '@/components/oauth/OAuthForgotPasswordPage';
import OAuthLoginPage from '@/components/oauth/OAuthLoginPage';
import OAuthProviderRedirectPage from '@/components/oauth/OAuthProviderRedirectPage';
import OAuthRegisterPage from '@/components/oauth/OAuthRegisterPage';
import OAuthResetPasswordPage from '@/components/oauth/OAuthResetPasswordPage';
import config from '@/config';
import Notistack from '@/lib/notistack';

import AppRoutes from './AppRoutes';

// Default pages

import PageLoading from './PageLoading';
import { NotAuth, Public, RequireAuth } from './lib';

const AdminRoutes = lazy(() => import('./AdminRoutes'));

export default function Routes(): JSX.Element {
  const Ssr = config.app.ssr === true ? Fragment : NoSsr;

  Notistack.registerSnackbar(useSnackbar());

  return (
    <Ssr>
      <TwoFARequired />
      <RouterRoutes>
        <Route
          path="/login"
          element={
            <NotAuth>
              <LoginPage />
            </NotAuth>
          }
        />
        <Route path="/oauth/authorize" element={<OAuthLoginPage />} />
        <Route path="/oauth/register" element={<OAuthRegisterPage />} />
        <Route path="/oauth/password/reset/:token" element={<OAuthResetPasswordPage />} />
        <Route path="/oauth/forgot-password" element={<OAuthForgotPasswordPage />} />
        <Route path="/oauth/confirm/:confirmationCode" element={<OAuthConfirmAccountPage />} />
        <Route path="/oauth/:provider/redirect" element={<OAuthProviderRedirectPage />} />
        <Route
          path="/loggedin"
          element={
            <NotAuth>
              <LoggedInCallbackPage />
            </NotAuth>
          }
        />
        {!!config.auth.registerEnabled && (
          <Route
            path="/register"
            element={
              <NotAuth>
                <RegisterPage />
              </NotAuth>
            }
          />
        )}
        {!config.flybase.oAuthEnabled && (
          <>
            <Route
              path="/forgot-password"
              element={
                <NotAuth>
                  <ForgotPasswordPage />
                </NotAuth>
              }
            />
            <Route
              path="/password/reset/:token"
              element={
                <NotAuth>
                  <ResetPasswordPage />
                </NotAuth>
              }
            />
            <Route path="/invite/:token" element={<ResetPasswordPage />} />
          </>
        )}
        <Route
          path="/switch"
          element={
            <RequireAuth>
              <SwitchTenantPage />
            </RequireAuth>
          }
        />
        <Route
          path="/admin/*"
          element={
            <RequireAuth>
              <Suspense fallback={<PageLoading />}>
                <AdminRoutes />
              </Suspense>
            </RequireAuth>
          }
        />
        <Route
          path="/*"
          element={
            <Public>
              <AppRoutes />
            </Public>
          }
        />
      </RouterRoutes>
    </Ssr>
  );
}
