import PageCard from '@boilerplate/components/PageCard';
import { Content } from '@boilerplate/lib/flybase/content/react';
import { Typography } from '@mui/material';
import React from 'react';

import { useTenantifiedUserStore } from '@/stores/UserStore';

export default function LandingPage() {
  const user = useTenantifiedUserStore();

  return (
    <PageCard heading="Welcome">
      <Content component={Typography}>home.message</Content>
      <p>Tenant: {user.currentTenant.name}</p>
    </PageCard>
  );
}
