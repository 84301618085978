import DialogBase from '@boilerplate/components/DialogBase';
import { Typography, Button } from '@mui/material';
import axios, { AxiosError } from 'axios';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

type DialogProps = {
  open: boolean;
  onClose: () => void;
  authorizationCode: string;
};

function OAuthTwoFactorDisableDialog({ open, onClose, authorizationCode }: DialogProps) {
  const [error, setError] = useState<AxiosError<{ message?: string }>>();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const handleSave = () => {
    if (loading) {
      return;
    }

    setLoading(true);
    axios
      .post('/oauth-mfa/disable', { authorizationCode })
      .then(() => {
        onClose();
      })
      .catch(setError)
      .finally(() => setLoading(false));
  };

  return (
    <DialogBase
      open={open}
      onClose={onClose}
      id="disable-2fa"
      title={t('auth:twoFactor.disable.title')}
      description={t('oauth:twoFactor.disable')}
      gutterBottom
      buttons={
        <Button onClick={handleSave} variant="text" disabled={loading}>
          {t('auth:twoFactor.disable.save')}
        </Button>
      }
    >
      {!!error && (
        <Typography color="error" align="center">
          {t(error.response?.data?.message, error.response?.data?.message || error.message || error.toString())}
        </Typography>
      )}
    </DialogBase>
  );
}

export default OAuthTwoFactorDisableDialog;
