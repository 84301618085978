import env from './env';

export default {
  // For Flybase lib
  publicKey: env('FLYBASE_PUBLIC_KEY', ''),

  // For Flybase CMS
  contentLocales: (env(import.meta.env.VITE_CONTENT_LOCALES, 'nl-NL,en-US') as string).split(',').map((locale) => locale.trim()),
  baseUrl: env('FLYBASE_BASE_URL', 'https://api.flystart.app'),
  frontendBaseUrl: env('FLYBASE_FRONTEND_BASE_URL', 'https://flystart.app'),
  redirectRoute: env('FLYBASE_OAUTH_REDIRECT_ROUTE', '/loggedin'),
  oAuthEnabled: env('FLYBASE_OAUTH_ENABLED', false),
};
