import allowedApiHostname from './allowedApiHostname.json';
import authorizationCode from './authorizationCode.json';
import content from './content.json';
import contentFill from './contentFill.json';
import defaultTranslations from './default.json';
import errorLog from './errorLog.json';
import eventLog from './eventLog.json';
import job from './job.json';
import mailLayout from './mailLayout.json';
import mailLayoutField from './mailLayoutField.json';
import mailLog from './mailLog.json';
import mailTemplate from './mailTemplate.json';
import mailTemplateField from './mailTemplateField.json';
import mailTemplateFill from './mailTemplateFill.json';
import missingContent from './missingContent.json';
import permission from './permission.json';
import resetPassword from './resetPassword.json';
import role from './role.json';
import tenant from './tenant.json';
import tenantUser from './tenantUser.json';
import tenantUserResetPassword from './tenantUserResetPassword.json';
import user from './user.json';
import userTenantRole from './userTenantRole.json';

export default {
  allowedApiHostname,
  authorizationCode,
  content,
  contentFill,
  default: defaultTranslations,
  errorLog,
  eventLog,
  job,
  mailLayout,
  mailLayoutField,
  mailLog,
  mailTemplate,
  mailTemplateField,
  mailTemplateFill,
  missingContent,
  permission,
  resetPassword,
  role,
  tenant,
  tenantUser,
  tenantUserResetPassword,
  user,
  userTenantRole,
};
