import DialogBase from '@boilerplate/components/DialogBase';
import { Button } from '@mui/material';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import Notistack from '@/lib/notistack';
import { doProviderLogin, useTenantInfo } from '@/lib/oauth';
import PageLoading from '@/routes/PageLoading';

const clearLocalStorage = () => {
  const fields = ['clientRedirectUri', 'providerRedirectUri', 'clientId', 'state'];

  fields.forEach((field) => {
    window.localStorage.removeItem(field);
  });
};

function OAuthProviderRedirectPage() {
  const [searchParams] = useSearchParams();
  const { tenantInfo, tenantInfoLoading } = useTenantInfo(window.localStorage.getItem('clientId') as string);
  const [openRegisterDisabledDialog, setOpenRegisterDisabledDialog] = useState(false);
  const [openEmailExistsDialog, setOpenEmailExistsDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const nav = useNavigate();
  const { provider } = useParams<{ provider?: 'google' | 'microsoft' }>();

  const code = decodeURIComponent(searchParams.get('code') as string);
  const providerRedirectUri = window.localStorage.getItem('providerRedirectUri') as string;

  const redirectUri = window.localStorage.getItem('clientRedirectUri') as string;
  const clientId = window.localStorage.getItem('clientId') as string;
  const state = window.localStorage.getItem('state');

  useEffect(() => {
    if (!tenantInfo || !provider) {
      return;
    }

    if (!['google', 'microsoft'].includes(provider)) {
      Notistack.toast(t('oauth:error.general'), { variant: 'error' });
      redirectToOAuthLoginPage();

      return;
    }

    doProviderLogin(provider, code, clientId, providerRedirectUri, redirectUri, state)
      .then((response) => {
        setLoading(false);
        clearLocalStorage();
        window.location.href = `${redirectUri}?code=${response.data.code}&state=${response.data.state}`;
      })
      .catch((error: AxiosError<{ message?: string }>) => {
        setLoading(false);
        handleErrors(error);
      });
  }, [tenantInfo, provider]);

  const handleRedirectToApp = () => {
    window.location.href = tenantInfo?.frontendBaseUri as string;
  };

  const handleErrors = (error: AxiosError<{ message?: string }>) => {
    if (error.response?.data.message === 'register_disabled') {
      setOpenRegisterDisabledDialog(true);
    } else if (error.response?.data.message === 'email_already_exists') {
      setOpenEmailExistsDialog(true);
    } else {
      Notistack.toast(t('oauth:error.general'), { variant: 'error' });
      redirectToOAuthLoginPage();
    }
  };

  const redirectToOAuthLoginPage = () => {
    nav(`/oauth/authorize?redirect_uri=${redirectUri}&client_id=${clientId}&state=${state}&response_type=code`);
  };

  if (tenantInfoLoading || loading) {
    return <PageLoading />;
  }

  return (
    <>
      <DialogBase
        open={openRegisterDisabledDialog}
        id="register-disabled-dialog"
        title={t('oauth:social.registerDisabled.title')}
        buttons={<Button onClick={handleRedirectToApp}>{t('oauth:backToApp', { appName: tenantInfo?.name })}</Button>}
        description={t('oauth:social.registerDisabled.description', { appName: tenantInfo?.name })}
      />

      <DialogBase
        open={openEmailExistsDialog}
        id="email-exists-dialog"
        title={t('oauth:social.accountExists.title')}
        buttons={<Button onClick={redirectToOAuthLoginPage}>{t('oauth:tryAgain')}</Button>}
        description={t('oauth:social.accountExists.description')}
      />
    </>
  );
}

export default OAuthProviderRedirectPage;
