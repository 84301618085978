import { PaperMenuProps, useDropdownStyles } from '@boilerplate/components/app/Layout/Header/DefaultHeaderStyles';
import { ArrowDropDown } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, ListItemIcon, Menu, MenuItem, TextField } from '@mui/material';
import axios from 'axios';
import React, { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { UserType } from '@/entities/user/type';
import { Tenant } from '@/graphql';
import Notistack from '@/lib/notistack';
import { setTenant, useAuthenticatedUserStore } from '@/stores/UserStore';

const useStyles = makeStyles()(({ spacing }) => ({
  textField: {
    marginTop: spacing(1),
  },
}));

export default function TenantMenu(props: Parameters<typeof Button>[0]) {
  const { t } = useTranslation();
  const location = useLocation();
  const { classes: dropdownClasses, cx } = useDropdownStyles();
  const { classes } = useStyles();

  const authUser = useAuthenticatedUserStore();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [createTenantDialogOpen, setCreateTenantDialogOpen] = useState<boolean>(false);
  const [newTenantName, setNewTenantName] = useState<string | undefined>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const open = Boolean(anchorEl);
  const allowAll = authUser.isSuperAdmin && location.pathname.startsWith('/admin');

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleSetTenant = (tenant?: UserType['currentTenant']) => {
    setTenant(tenant).catch(console.error);
    setAnchorEl(null);
  };

  function handleSubmit(e) {
    e.preventDefault();
    setIsSubmitting(true);

    axios
      .post('/tenant', { name: newTenantName, timezone: Intl.DateTimeFormat().resolvedOptions().timeZone })
      .then((res) => {
        Notistack.toast(t('auth:completeRegistration.success'), { variant: 'success' });

        setTenant(res.data as Tenant).catch((error) => console.error(error));
      })
      .catch(() => {
        Notistack.toast(t('auth:completeRegistration.error'), { variant: 'error' });
      })
      .finally(() => {
        setIsSubmitting(false);
        setCreateTenantDialogOpen(false);
      });
  }

  if (authUser.tenants.length < 1 && !allowAll) {
    return null;
  }

  return (
    <>
      <Button onClick={handleClick} {...props}>
        {authUser?.currentTenant?.name ?? t('tenants:allTenants')}

        <ArrowDropDown
          className={cx({
            [dropdownClasses.caret]: true,
            [dropdownClasses.caretActive]: Boolean(anchorEl),
          })}
        />
      </Button>
      <Menu
        id="tenant-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        PaperProps={PaperMenuProps}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {authUser?.tenants?.map((tenant) => {
          return (
            <MenuItem key={tenant.id} onClick={() => handleSetTenant(tenant)}>
              {tenant.name}
            </MenuItem>
          );
        })}

        {allowAll && <MenuItem onClick={() => handleSetTenant()}>{t('tenants:allTenants')}</MenuItem>}
        <Divider />
        <MenuItem
          onClick={() => {
            setCreateTenantDialogOpen(true);
          }}
        >
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          {t('tenants:createTenant')}
        </MenuItem>
      </Menu>

      <Dialog
        open={createTenantDialogOpen}
        onClose={() => {
          setCreateTenantDialogOpen(false);
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>{t('tenants:createTenant')}</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            value={newTenantName}
            onChange={(e) => {
              setNewTenantName(e.target.value);
            }}
            className={classes.textField}
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit" variant="contained" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
            {t('crud:create')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
