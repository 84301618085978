import DialogBase from '@boilerplate/components/DialogBase';
import FormikTextField from '@boilerplate/components/FormikTextField';
import { Button } from '@mui/material';
import { AxiosError } from 'axios';
import { Form, Formik, FormikErrors, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';

import Notistack from '@/lib/notistack';
import { ErrorMessage, requestOAuthResetPassword, useTenantInfo } from '@/lib/oauth';
import PageLoading from '@/routes/PageLoading';

import OAuthLayout from './Layout/OAuthLayout';

interface FormValues {
  email: string;
}

const initialValues: FormValues = {
  email: '',
};

function OAuthForgotPasswordPage() {
  const [searchParams] = useSearchParams();
  const { tenantInfo, tenantInfoLoading } = useTenantInfo(searchParams.get('client_id') ?? '');
  const { t } = useTranslation();

  const [showDialog, setShowDialog] = useState(false);
  const [openExternalProviderDialog, setOpenExternalProviderDialog] = useState(false);

  const validate = (values: FormValues) => {
    const errors: FormikErrors<FormValues> = {};

    if (!values.email) {
      errors.email = t('auth:validation.required');
    } else if (!isEmail(values.email)) {
      errors.email = t('auth:validation.emailInvalid');
    }

    return errors;
  };

  const handleSubmit = (values: FormValues, { setSubmitting, setFieldError, resetForm }: FormikHelpers<FormValues>) => {
    requestOAuthResetPassword(values.email, searchParams.get('client_id') ?? '')
      .then(() => {
        resetForm();
        setShowDialog(true);
      })
      .catch((error: AxiosError<{ message?: string }>) => {
        if (error.response?.data.message === 'external_identity_provider') {
          setOpenExternalProviderDialog(true);
        } else {
          Notistack.toast(t('auth:forgotPassword.error.unknown'));
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  if (tenantInfoLoading) {
    return <PageLoading />;
  }

  if (!tenantInfo) {
    return <ErrorMessage message={t('oauth:error.invalidClientId')} />;
  }

  return (
    <OAuthLayout tenantName={tenantInfo?.name} heading={t('auth:forgotPassword.title')}>
      <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
          <Form>
            <FormikTextField type="email" name="email" label={t('auth:fields.email')} />

            <Button type="submit" disabled={isSubmitting} size="large" variant="contained" color="primary" sx={{ marginTop: '1rem' }}>
              {t('auth:forgotPassword.submit')}
            </Button>
          </Form>
        )}
      </Formik>

      <p>
        <Link to={tenantInfo?.frontendBaseUri}>{t('auth:links.login')}</Link>
      </p>

      <DialogBase open={showDialog} id="forgot-password-dialog" description={t('auth:forgotPassword.sent')} />
      <DialogBase
        open={openExternalProviderDialog}
        id="external-provider-dialog"
        title={t('oauth:passwordReset.createdWithProvider.title')}
        buttons={
          <Button
            onClick={() => {
              setOpenExternalProviderDialog(false);
            }}
          >
            {t('strings:close')}
          </Button>
        }
        description={t('oauth:passwordReset.createdWithProvider.description')}
      />
    </OAuthLayout>
  );
}

export default OAuthForgotPasswordPage;
