import DialogBase from '@boilerplate/components/DialogBase';
import FormikCheckBox from '@boilerplate/components/FormikCheckBox';
import FormikTextField from '@boilerplate/components/FormikTextField';
import PasswordStrengthIndicator from '@boilerplate/components/auth/PasswordStrengthIndicator';
import { Button, TextField } from '@mui/material';
import { AxiosError } from 'axios';
import { Formik, FormikErrors, FormikHelpers, Form } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import config from '@/config';
import Notistack from '@/lib/notistack';
import { ErrorMessage, checkOAuthPasswordResetToken, resetOAuthPassword, useTenantInfo } from '@/lib/oauth';
import { passwordStrength } from '@/lib/passwordStrength';
import PageLoading from '@/routes/PageLoading';

import OAuthLayout from './Layout/OAuthLayout';

interface FormValues {
  password: string;
  passwordConfirmation: string;
  totpToken: string;
  termsAndConditions?: boolean;
}

const initialValues: FormValues = {
  password: '',
  passwordConfirmation: '',
  totpToken: '',
  termsAndConditions: false,
};

function OAuthResetPasswordPage() {
  const { t } = useTranslation();
  const nav = useNavigate();
  const { token = '' } = useParams();
  const [searchParams] = useSearchParams();
  const totpInputRef = useRef<HTMLInputElement>();

  const [email, setEmail] = useState('');
  const [show2FA, setShow2FA] = useState(false);
  const [openExpiredDialog, setOpenExpiredDialog] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [openResetSuccessfulDialog, setOpenResetSuccessfulDialog] = useState(false);
  const [termsAndConditionsRequired, setTermsAndConditionsRequired] = useState(false);
  const [isInvite, setIsInvite] = useState(false);

  const { tenantInfo, tenantInfoLoading } = useTenantInfo(searchParams.get('client_id') ?? '');

  const validate = (values: FormValues) => {
    const errors: FormikErrors<FormValues> = {};

    if (!values.password) {
      errors.password = t('auth:validation.required');
    } else if (passwordStrength(values.password) < config.auth.passwordStrength) {
      errors.password = t('auth:validation.passwordTooWeak');
    } else if (values.password !== values.passwordConfirmation) {
      errors.passwordConfirmation = t('auth:validation.passwordConfirmationMustMatch');
    }

    if (tenantInfo?.termsAndConditions && termsAndConditionsRequired && !values.termsAndConditions) {
      errors.termsAndConditions = t('auth:validation.required');
    }

    return errors;
  };

  const handleSubmit = (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    resetOAuthPassword(token, values.password, values.termsAndConditions)
      .then(() => {
        setOpenResetSuccessfulDialog(true);
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 410) {
          if (error.response?.data?.message === 'expired_invite') {
            setIsInvite(true);
          }

          setOpenExpiredDialog(true);
        } else if (error.response?.status === 404) {
          setNotFound(true);
        } else {
          Notistack.toast(t('oauth:passwordReset.error.passwordReset'), { variant: 'error' });
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleFixExpired = () => {
    nav(`/oauth/forgot-password?client_id=${searchParams.get('client_id') ?? ''}`);
  };

  const handleRedirectToApp = () => {
    window.location.href = tenantInfo?.frontendBaseUri;
  };

  useEffect(() => {
    checkOAuthPasswordResetToken(token)
      .then((response) => {
        setEmail(response.email);
        setTermsAndConditionsRequired(response.termsAndConditionsRequired);
        setIsInvite(response.type === 'invite');
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 410) {
          if (error.response?.data?.message === 'expired_invite') {
            setIsInvite(true);
          }

          setOpenExpiredDialog(true);
        } else {
          setNotFound(true);
        }
      });
  }, [token]);

  if (tenantInfoLoading) {
    return <PageLoading />;
  }

  if (!tenantInfo) {
    return <ErrorMessage message={t('oauth:error.invalidClientId')} />;
  }

  if (notFound) {
    return <ErrorMessage message={t('oauth:error.invalidPasswordResetToken')} />;
  }

  return (
    <OAuthLayout heading={isInvite ? t('auth:invite.title') : t('auth:resetPassword.title')} tenantName={tenantInfo?.name}>
      <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit}>
        {({ isSubmitting, values }) => (
          <Form>
            <TextField
              id="email"
              disabled
              value={email}
              margin="normal"
              label={t('auth:fields.email')}
              type="email"
              variant="outlined"
              fullWidth
            />

            {!show2FA ? (
              <>
                <FormikTextField type="password" name="password" label={t('auth:fields.newPassword')} />

                <PasswordStrengthIndicator password={values.password} />

                <FormikTextField type="password" name="passwordConfirmation" label={t('auth:fields.passwordConfirmation')} />
              </>
            ) : (
              <FormikTextField type="text" name="totpToken" label={t('auth:fields.token')} ref={totpInputRef} />
            )}

            {tenantInfo?.termsAndConditions && termsAndConditionsRequired && (
              <FormikCheckBox
                name="termsAndConditions"
                label={
                  <Trans
                    i18nKey="auth:fields.termsAndConditions"
                    components={{
                      a: <a href={tenantInfo?.termsAndConditions} target="_blank" rel="noreferrer" />,
                    }}
                  />
                }
              />
            )}

            <Button type="submit" disabled={isSubmitting} size="large" variant="contained" color="primary" sx={{ marginTop: '1rem' }} fullWidth>
              {isInvite ? t('auth:invite.submit') : t('auth:resetPassword.submit')}
            </Button>
          </Form>
        )}
      </Formik>

      <DialogBase
        open={openExpiredDialog}
        id="expired-dialog"
        title={t('auth:resetPassword.tokenExpired.title')}
        buttons={
          <Button onClick={handleFixExpired} sx={{ display: isInvite ? 'none' : 'block' }}>
            {t('auth:resetPassword.tokenExpired.requestNew')}
          </Button>
        }
        description={isInvite ? t('auth:invite.tokenExpired.description') : t('auth:resetPassword.tokenExpired.description')}
      />

      <DialogBase
        open={openResetSuccessfulDialog}
        id="successful-dialog"
        title={t('crud:updatedItem', { item: t('entityFields:tenantUser.password') })}
        buttons={<Button onClick={handleRedirectToApp}>{t('oauth:backToApp', { appName: tenantInfo?.name })}</Button>}
        description={isInvite ? t('oauth:invite.success') : t('oauth:passwordReset.success')}
      />
    </OAuthLayout>
  );
}

export default OAuthResetPasswordPage;
