import { Alert, AlertTitle } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import React from 'react';

type TenantInfo = {
  name: string;
  registerEnabled: boolean;
  termsAndConditions?: string;
  redirectUri: string;
  frontendBaseUri: string;
  googleAuthEnabled?: boolean;
  microsoftAuthEnabled?: boolean;
};

type ResetPasswordInfo = {
  email: string;
  type: string;
  termsAndConditionsRequired: boolean;
};

export function getTenantInfo(publicKey: string): Promise<TenantInfo> {
  return axios.get(`/tenant/${publicKey}`).then((response) => response.data);
}

export function useTenantInfo(publicKey: string) {
  const [tenantInfo, setTenantInfo] = useState<TenantInfo | undefined>();
  const [tenantInfoLoading, setTenantInfoLoading] = useState(true);

  useEffect(() => {
    if (publicKey) {
      getTenantInfo(publicKey)
        .then((response) => {
          setTenantInfo(response);
          setTenantInfoLoading(false);
        })
        .catch(() => {
          setTenantInfo(undefined);
          setTenantInfoLoading(false);
        });
    }
  }, [publicKey]);

  return { tenantInfo, tenantInfoLoading };
}

export function ErrorMessage({ message }: { message: string }) {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        {message}
      </Alert>
    </div>
  );
}

export function checkOAuthPasswordResetToken(token: string): Promise<ResetPasswordInfo> {
  return axios
    .post('oauth/password/checkToken', {
      token,
    })
    .then((response) => response.data);
}

export function resetOAuthPassword(token: string, password: string, termsAndConditions?: boolean) {
  return axios.post('/oauth/password/reset', {
    token,
    password,
    termsAndConditions,
  });
}

export function requestOAuthResetPassword(email: string, clientId: string) {
  return axios.post('/oauth/password/request-reset', {
    email,
    client_id: clientId,
  });
}

export function resendConfirmEmail(email: string, clientId: string) {
  return axios.post('/oauth/resend-confirm', { email, client_id: clientId });
}

export function confirmAccount(confirmationCode: string) {
  return axios.post(`/oauth/confirm/${confirmationCode}`);
}

export function getProviderOAuthUrl(provider: 'google' | 'microsoft', clientId: string) {
  return axios.get(`/oauth/${provider}?client_id=${clientId}`);
}

export function doProviderLogin(
  provider: 'google' | 'microsoft',
  authorizationCode: string,
  clientId: string,
  redirectUri: string,
  clientRedirectUri: string,
  state?: string | null
) {
  return axios.post(`oauth/${provider}/token`, {
    client_id: clientId,
    provider_redirect_uri: redirectUri,
    client_redirect_uri: clientRedirectUri,
    state,
    code: authorizationCode,
  });
}
