import DialogBase from '@boilerplate/components/DialogBase';
import Loading from '@boilerplate/components/Loading';
import { saveToFile, useEnableDialogStyles } from '@boilerplate/components/app/Profile/TwoFactor/EnableDialog';
import useCountdown from '@boilerplate/lib/useCountdown';
import { TextField, Typography, Button, Stepper, Step, StepLabel, List, ListItem, ListItemText, DialogContentText } from '@mui/material';
import axios, { AxiosError } from 'axios';
import React, { useState, useEffect, MouseEvent, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { useSearchParams } from 'react-router-dom';

type OAuthTwoFactorEnableDialogProps = {
  open: boolean;
  onClose: () => void;
  password: string;
  email: string;
  isRegenerate?: boolean;
  authorizationCode?: string;
};

function OAuthTwoFactorEnableDialog({ open, isRegenerate, onClose, password, email, authorizationCode }: OAuthTwoFactorEnableDialogProps) {
  const [secret, setSecret] = useState<{ secret: string; uri: string } | null>(null);
  const [error, setError] = useState<AxiosError<{ message?: string }>>();
  const [setupLoading, setSetupLoading] = useState(false);
  const [closeTimeout, setCloseTimeout] = useCountdown(0);
  const [backupCodes, setBackupCodes] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState('');
  const { t, i18n } = useTranslation();
  const { classes } = useEnableDialogStyles();
  const [searchParams] = useSearchParams();
  const clientId = searchParams.get('client_id') as string;

  const handleSaveToFile = () => {
    saveToFile(backupCodes, new Date().toLocaleString(i18n.language, { dateStyle: 'short' }));

    setCloseTimeout(0);
  };

  const handleClose = () => {
    if (closeTimeout <= 0) {
      onClose();
    }
  };

  const handleSetupFinish = (event: MouseEvent<HTMLButtonElement> | FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (loading) {
      return;
    }

    setLoading(true);
    setError(null);

    if (isRegenerate) {
      axios
        .post('/oauth-mfa/regenerate-backup-codes', { authorizationCode })
        .then(({ data }) => {
          setBackupCodes(data.backupCodes);
          setLoading(true);
          setCloseTimeout(15);
        })
        .catch(setError)
        .finally(() => {
          setLoading(false);
        });
    } else {
      axios
        .post('/oauth-mfa/verify', { email, password, client_id: clientId, token })
        .then(({ data }) => {
          setBackupCodes(data.backupCodes);
          setLoading(true);
          setCloseTimeout(15);
        })
        .catch(setError)
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (open && !isRegenerate) {
      setSetupLoading(true);
      setLoading(true);

      axios
        .post('/oauth-mfa/setup', { email, password, client_id: clientId })
        .then(({ data }) => setSecret(data))
        .catch((err: AxiosError<{ message?: string }>) => {
          if (err.response?.data.message === 'auth:twoFactor.error.alreadyEnabled') {
            onClose();
          } else {
            setError(err);
          }
        })
        .finally(() => {
          setSetupLoading(false);
          setLoading(false);
        });
    }
  }, [open, isRegenerate, email, password, clientId, onClose]);

  useEffect(() => {
    if (!open) {
      setSecret(null);
      setSetupLoading(false);
      setCloseTimeout(0);
      setBackupCodes([]);
      setLoading(false);
      setToken('');
      setError(undefined);
    }
  }, [open, setCloseTimeout]);

  return (
    <DialogBase
      open={open}
      onClose={handleClose}
      title={t('auth:twoFactor.enable.title')}
      id="enable-2fa"
      noDefaultButtons
      buttons={
        <>
          <Button onClick={handleClose} variant="text" disabled={closeTimeout > 0}>
            {backupCodes.length <= 0 ? t('auth:twoFactor.close') : t('auth:twoFactor.enable.close')}
            {closeTimeout > 0 && <>&nbsp; ({closeTimeout}s)</>}
          </Button>
          {backupCodes.length <= 0 && (
            <Button variant="text" onClick={handleSetupFinish} disabled={(isRegenerate ? !password : !token || !password) || loading}>
              {t('auth:twoFactor.enable.next')}
            </Button>
          )}
        </>
      }
    >
      {!setupLoading ? (
        <>
          <Stepper className={classes.stepper} activeStep={backupCodes.length > 0 ? 1 : 0}>
            <Step>
              <StepLabel>{t('auth:twoFactor.enable.steps.configure')}</StepLabel>
            </Step>
            <Step>
              <StepLabel>{t('auth:twoFactor.enable.steps.saveBackup')}</StepLabel>
            </Step>
          </Stepper>

          <DialogContentText id="enable-2fa-dialog-description" gutterBottom>
            {backupCodes.length <= 0
              ? t(isRegenerate ? 'oauth:twoFactor.regenerate' : 'auth:twoFactor.enable.description.configure.enable')
              : t('auth:twoFactor.enable.description.saveBackup.main')}
          </DialogContentText>

          {backupCodes.length <= 0 ? (
            <form onSubmit={handleSetupFinish}>
              {!isRegenerate && (
                <>
                  {secret?.uri && <QRCode value={secret.uri} className={classes.secretQr} />}
                  <Typography className={classes.secretCode} variant="caption" align="center" gutterBottom>
                    {secret?.secret}
                  </Typography>
                </>
              )}
              {!isRegenerate && (
                <TextField
                  onChange={(e) => setToken(e.target.value)}
                  label={t('auth:twoFactor.enable.token')}
                  value={token}
                  margin="dense"
                  type="text"
                  fullWidth
                  required
                />
              )}
              <input type="submit" hidden />
            </form>
          ) : (
            <>
              <Typography gutterBottom>{t('auth:twoFactor.enable.description.saveBackup.warning')}</Typography>
              <List className={classes.backupCodes}>
                {backupCodes.map((code) => (
                  <ListItem key={code}>
                    <ListItemText primary={code} />
                  </ListItem>
                ))}
              </List>
              <Button variant="outlined" onClick={handleSaveToFile} fullWidth>
                {t('auth:twoFactor.enable.saveFile')}
              </Button>
            </>
          )}
        </>
      ) : (
        <Loading />
      )}
      {!!error && (
        <Typography color="error" align="center">
          {t(error.response?.data?.message, error.response?.data?.message || error.message || error.toString())}
        </Typography>
      )}
    </DialogBase>
  );
}

export default OAuthTwoFactorEnableDialog;
