import customActions from '@boilerplate/lib/customActions';
import { removeExcludedFormFields } from '@boilerplate/lib/formSchema';
import Entity from '@boilerplate/types/entity';
import { getI18n } from 'react-i18next';

import UserBaseSimpleSchema from '@/_base/user/UserBaseSimpleSchema';
import { UserBaseTableSchemaWithoutActions } from '@/_base/user/UserBaseTableSchema';
import UsersBaseTable from '@/_base/user/components/UserBaseTable';

import ActionButtons from './ActionButtons';
import ProfileAvatar from './ProfileAvatar';
import UserInsertForm from './UserInsertForm';
import UserModel from './UserModel';
import UserUpdateForm from './UserUpdateForm';
import UserView from './UserView';

const { t } = getI18n();

const UserEntity: Entity<typeof UserModel> = {
  name: 'user',

  simpleSchema: UserBaseSimpleSchema,

  insert: {
    simpleSchema: removeExcludedFormFields(UserBaseSimpleSchema).extend({
      role: {
        type: String,
        label: t(['entityFields:user.role']),
        relation: {
          name: 'ManyToOne',
          type: 'ManyToOne',
          model: 'role',
        },
        form: true,
      },
      tenant: {
        type: String,
        label: t(['entityFields:user.tenant']),
        relation: {
          name: 'ManyToOne',
          type: 'ManyToOne',
          model: 'tenant',
        },
        form: true,
      },
      invite: {
        type: Boolean,
        label: t(['entityFields:user.invite']),
        form: true,
        required: false,
      },
    }),
    orderSchema: [['name', 'email'], ['active'], ['avatar'], ['tenant'], ['role'], ['invite'], ['locale', 'timeZone']],
    component: UserInsertForm,
  },

  update: {
    simpleSchema: removeExcludedFormFields(UserBaseSimpleSchema),
    orderSchema: [['name', 'email'], ['active'], ['avatar'], ['locale', 'timeZone']],
    component: UserUpdateForm,
  },

  table: {
    schema: [
      {
        field: 'avatar',
        label: 'Avatar',
        cell: ProfileAvatar,
        align: 'center',
      },
      ...UserBaseTableSchemaWithoutActions,
      customActions(ActionButtons, t),
    ],
    component: UsersBaseTable,
  },

  view: {
    component: UserView,
  },

  model: UserModel,

  routes: {
    view: UserView
  },

  settings: {
    displayField: 'name',
  },
};

export default UserEntity;
