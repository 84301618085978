import { merge } from 'lodash';

import Content from './content';
import { Config, FullConfig, defaultConfig } from './lib';

export default class Flybase {
  #config: FullConfig;

  content: Content;

  constructor(config: Config) {
    this.#config = merge({}, defaultConfig, config);
    this.content = new Content(this.#config);
  }
}
