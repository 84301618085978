const schema = [
        ['name'],
        ['timeZone'],
        ['redirectUri'],
        ['termsAndConditions'],
        ['frontendBaseUri'],
        ['googleAuthEnabled'],
        ['googleId'],
        ['googleSecret'],
        ['microsoftAuthEnabled'],
        ['microsoftId'],
        ['microsoftSecret'],
        ['microsoftTenantId'],
        ['inviteExpiration'],
        ['passwordResetExpiration'],
        ['registerEnabled'],
        ['confirmAccountEnabled'],
];

export default schema;
