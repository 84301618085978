import DialogBase from '@boilerplate/components/DialogBase';
import FormikTextField from '@boilerplate/components/FormikTextField';
import { Button, Divider, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import axios, { AxiosError } from 'axios';
import { Formik, FormikErrors, FormikHelpers, Form, FormikProps } from 'formik';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';
import isEmail from 'validator/es/lib/isEmail';

import Notistack from '@/lib/notistack';
import { useTenantInfo, ErrorMessage, resendConfirmEmail, getProviderOAuthUrl } from '@/lib/oauth';
import PageLoading from '@/routes/PageLoading';

import OAuthLayout from './Layout/OAuthLayout';
import OAuthTwoFactorDisableDialog from './TwoFactor/OAuthTwoFactorDisableDialog';
import OAuthTwoFactorEnableDialog from './TwoFactor/OAuthTwoFactorEnableDialog';

const useStyles = makeStyles({
  blackButton: {
    color: '#363636',
    borderColor: 'gray',
  },
});

interface FormValues {
  email: string;
  password: string;
  totpToken: string;
}

const initialValues: FormValues = {
  email: '',
  password: '',
  totpToken: '',
};

function OAuthLoginPage() {
  const [searchParams] = useSearchParams();

  const { t } = useTranslation();
  const classes = useStyles();
  const formRef = useRef<FormikProps<FormValues>>();
  const totpInputRef = useRef<HTMLInputElement>();

  const [show2FA, setShow2FA] = useState(false);
  const [openNotActiveDialog, setOpenNotActiveDialog] = useState(false);
  const [openNotConfirmedDialog, setOpenNotConfirmedDialog] = useState(false);
  const [loginResponse, setLoginResponse] = useState(null);
  const [resendConfirmButtonDisabled, setResendConfirmButtonDisabled] = useState(false);
  const [busy, setBusy] = useState(false);

  const clientId = searchParams.get('client_id') as string;
  const action = searchParams.get('action') as string;
  const loginHint = searchParams.get('login_hint');
  const { tenantInfo, tenantInfoLoading } = useTenantInfo(clientId);

  if (loginHint) {
    initialValues.email = loginHint as string;
  }

  // 2FA-related hooks
  const [mfaOpen, setMfaOpen] = useState(false);
  const [mfaIsRegenerate, setMfaIsRegenerate] = useState(false);
  const [mfaDisableOpen, setMfaDisableOpen] = useState(false);

  const handleSubmit = (values: FormValues, { setSubmitting, setErrors }: FormikHelpers<FormValues>) => {
    const authRequest = {
      email: values.email,
      password: values.password,
      totp: values.totpToken,
      response_type: searchParams.get('response_type'),
      client_id: searchParams.get('client_id'),
      redirect_uri: searchParams.get('redirect_uri'),
      state: searchParams.get('state'),
    };

    axios
      .post('/oauth/authorize', authRequest)
      .then((response) => {
        setLoginResponse(response.data);

        switch (action) {
          case 'mfa-regenerate':
            setMfaIsRegenerate(true);
          // eslint-disable-next-line no-fallthrough
          case 'mfa-enable':
            setMfaOpen(true);
            break;
          case 'mfa-disable':
            setMfaDisableOpen(true);
            break;
          default:
            window.location.href = `${searchParams.get('redirect_uri')}?code=${response.data.code}&state=${response.data.state}`;
            break;
        }
      })
      .catch((error: AxiosError<{ message?: string }>) => {
        if (error?.response?.data?.message === 'email_not_confirmed') {
          setOpenNotConfirmedDialog(true);

          return;
        }

        if (error?.response?.data?.message === 'not_active') {
          setOpenNotActiveDialog(true);

          return;
        }

        if (error?.response?.data?.message === 'auth:validateUser.totpTokenRequired') {
          setShow2FA(true);

          requestAnimationFrame(() => {
            totpInputRef.current?.focus?.();
          });
        }

        const errorMessage = t([error?.response?.data?.message, 'auth:login.error']);

        console.error(error);

        setErrors({
          email: '',
          password: errorMessage,
          totpToken: errorMessage,
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleRedirectToApp = () => {
    window.location.href = `${searchParams.get('redirect_uri')}?code=${loginResponse?.code}&state=${loginResponse?.state}`;
  };

  const mfaHandleClose = () => {
    setMfaOpen(false);
    setMfaIsRegenerate(false);
    setMfaDisableOpen(false);

    if (loginResponse?.code) {
      handleRedirectToApp();
    } else {
      searchParams.set('action', '');
    }
  };

  const validate = (values: FormValues) => {
    const errors: FormikErrors<FormValues> = {};

    if (!values.email) {
      errors.email = t('auth:validation.required');
    } else if (!isEmail(values.email)) {
      errors.email = t('auth:validation.emailInvalid');
    }

    if (!values.password) {
      errors.password = t('auth:validation.required');
    }

    return errors;
  };

  if (tenantInfoLoading) {
    return <PageLoading />;
  }

  if (!tenantInfo && !tenantInfoLoading) {
    return <ErrorMessage message={t('oauth:error.invalidClientId')} />;
  }

  if (tenantInfo.redirectUri !== searchParams.get('redirect_uri')) {
    return <ErrorMessage message={t('oauth:error.invalidRedirectUri')} />;
  }

  const handleCloseNotActive = () => {
    setOpenNotActiveDialog(false);
  };

  const handleCloseNotConfirmed = () => {
    setOpenNotConfirmedDialog(false);
  };

  const handleFixNotConfirmed = () => {
    if (formRef.current?.values.email) {
      setResendConfirmButtonDisabled(true);
      resendConfirmEmail(formRef.current?.values.email, clientId)
        .then(() => {
          Notistack.toast(t('auth:login.notConfirmed.resendSuccess'), { variant: 'success' });
          setResendConfirmButtonDisabled(false);
        })
        .catch((error) => {
          Notistack.toast(t(error?.response?.data?.message, 'auth:login.notConfirmed.resendError'), {
            variant: 'error',
          });
        })
        .finally(() => {
          setOpenNotConfirmedDialog(false);
        });
    } else {
      Notistack.toast(t('auth:login.notConfirmed.resendError'), {
        variant: 'error',
      });
    }
  };

  const handleProviderLogin = (provider: 'google' | 'microsoft') => {
    setBusy(true);
    getProviderOAuthUrl(provider, clientId)
      .then((response) => {
        window.localStorage.setItem('clientId', clientId);
        window.localStorage.setItem('clientRedirectUri', searchParams.get('redirect_uri') as string);

        if (searchParams.get('state')) {
          window.localStorage.setItem('state', searchParams.get('state') as string);
        }

        window.localStorage.setItem('providerRedirectUri', response.data.redirectUri);

        window.location.href = response.data.authUrl;
      })
      .catch(() => {
        setBusy(false);
        Notistack.toast(t('oauth:error.general'), { variant: 'error' });
      });
  };

  return (
    <OAuthLayout heading={t('auth:login.title')} tenantName={tenantInfo?.name} maxWidth="xs">
      <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit} innerRef={formRef}>
        {({ isSubmitting }) => (
          <Form>
            {!show2FA ? (
              <>
                <FormikTextField type="email" name="email" label={t('auth:fields.email')} />

                <FormikTextField type="password" name="password" label={t('auth:fields.password')} />
              </>
            ) : (
              <FormikTextField type="text" name="totpToken" label={t('auth:fields.token')} ref={totpInputRef} />
            )}

            <Button type="submit" disabled={isSubmitting} size="large" variant="contained" color="primary" sx={{ marginTop: '1rem' }} fullWidth>
              {t('auth:login.submit')}
            </Button>
          </Form>
        )}
      </Formik>

      {!show2FA && (
        <>
          {(tenantInfo.googleAuthEnabled || tenantInfo.microsoftAuthEnabled) && !action && (
            <>
              <Divider sx={{ marginY: '0.8rem' }}>{t('strings:or')}</Divider>
              <Stack spacing={2}>
                {tenantInfo.googleAuthEnabled && (
                  <Button
                    className={classes.blackButton}
                    onClick={() => handleProviderLogin('google')}
                    fullWidth
                    disabled={busy}
                    variant="outlined"
                    startIcon={<img src="/images/icons/google.svg" alt="Google logo" />}
                  >
                    {t('oauth:signInWith')} Google
                  </Button>
                )}
                {tenantInfo.microsoftAuthEnabled && (
                  <Button
                    fullWidth
                    disabled={busy}
                    onClick={() => handleProviderLogin('microsoft')}
                    variant="outlined"
                    className={classes.blackButton}
                    startIcon={<img src="/images/icons/microsoft.svg" alt="Google logo" />}
                  >
                    {t('oauth:signInWith')} Microsoft
                  </Button>
                )}
              </Stack>
            </>
          )}

          <Divider sx={{ marginTop: '1.5rem' }} />
          {tenantInfo?.registerEnabled && !action && (
            <p>
              <Link to={`/oauth/register?${searchParams.toString()}`}>{t('auth:links.dontHaveAnAccountYetRegister')}</Link>
            </p>
          )}
          <p>
            <Link to={`/oauth/forgot-password?${searchParams.toString()}`}>{t('auth:links.forgotPassword')}</Link>
          </p>
        </>
      )}

      <DialogBase
        id="not-active"
        open={openNotActiveDialog}
        onClose={handleCloseNotActive}
        title={t('auth:login.notActive.title')}
        description={t('auth:login.notActive.description')}
      />

      <DialogBase
        id="not-confirmed"
        open={openNotConfirmedDialog}
        onClose={handleCloseNotConfirmed}
        title={t('auth:login.notConfirmed.title')}
        description={t('auth:login.notConfirmed.description')}
        buttons={
          <Button variant="text" onClick={handleFixNotConfirmed} disabled={resendConfirmButtonDisabled}>
            {t('auth:login.notConfirmed.requestNew')}
          </Button>
        }
      />

      <OAuthTwoFactorEnableDialog
        open={mfaOpen}
        isRegenerate={mfaIsRegenerate}
        onClose={mfaHandleClose}
        email={formRef.current?.values.email as string}
        password={formRef.current?.values.password as string}
        authorizationCode={loginResponse?.code as string}
      />

      <OAuthTwoFactorDisableDialog open={mfaDisableOpen} onClose={mfaHandleClose} authorizationCode={loginResponse?.code} />
    </OAuthLayout>
  );
}

export default OAuthLoginPage;
