import { Box, Button, TextField, Typography } from '@mui/material';
import * as Sentry from '@sentry/browser';
import axios from 'axios';
import { TFunction } from 'i18next';
import React, { ErrorInfo, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import Notistack from '@/lib/notistack';
import UserStore, { AuthenticatedUser } from '@/stores/UserStore';
import { StoreType } from '@/stores/store/types';

type ErrorBoundaryComponentProps = {
  t: TFunction;
  pathname: string;
  children: ReactNode;
};

type ErrorBoundaryComponentState = {
  hasError: boolean;
  errorFeedbackText: string;
  sentryId?: string;
};

class ErrorBoundaryComponent extends React.Component<ErrorBoundaryComponentProps, ErrorBoundaryComponentState> {
  state = {
    hasError: false,
    errorFeedbackText: '',
    sentryId: '',
  };

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidUpdate(prevProps: ErrorBoundaryComponentProps) {
    const { hasError } = this.state;
    const { pathname } = this.props;

    if (hasError && pathname !== prevProps.pathname) {
      this.setState({
        hasError: false,
      });
    }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const sentryId = Sentry.captureException(error, (scope) => {
      scope.setExtra('component_stack', errorInfo?.componentStack ?? errorInfo);

      return scope;
    });

    axios.post('/error-log', { exception: error?.message, stack: error?.stack, sentryId }).catch(console.error);

    this.setState({ sentryId });
  }

  handleCrashReport() {
    const { errorFeedbackText, sentryId } = this.state;

    if (errorFeedbackText.length === 0) {
      return;
    }

    const email = (UserStore as StoreType<AuthenticatedUser>).get('email') || '?';

    axios
      .post('/feedback/crash', { feedback: errorFeedbackText, email, sentryId })
      .then(() => {
        const { t } = this.props;

        this.setState({
          errorFeedbackText: '',
        });

        Notistack.toast(t('strings:error.send'));

        setTimeout(() => {
          window.location.href = '/';
        }, 3500);
      })
      .catch((err) => {
        console.error(err);
        Sentry.captureException(err);
      });
  }

  render() {
    const { errorFeedbackText, hasError } = this.state;
    const { children, t } = this.props;

    if (hasError) {
      return (
        <Box height="100%" width="clamp(270px, 100vw, 50vw)" marginX="auto">
          <Typography variant="h1">Error</Typography>
          <hr />
          <Typography variant="h4">Crash Report</Typography>
          <TextField
            placeholder={t('strings:error.helpText')}
            variant="outlined"
            fullWidth
            multiline
            rows={6}
            value={errorFeedbackText}
            onChange={(event) => this.setState({ errorFeedbackText: event.target.value })}
          />
          <Button style={{ float: 'right' }} onClick={() => this.handleCrashReport()}>
            {t('strings:send')}
          </Button>
        </Box>
      );
    }

    return children;
  }
}

type ErrorBoundaryProps = {
  children: React.ReactNode;
};

export default function ErrorBoundary({ children }: ErrorBoundaryProps) {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  return (
    <ErrorBoundaryComponent t={t} pathname={pathname}>
      {children}
    </ErrorBoundaryComponent>
  );
}
