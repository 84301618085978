import DialogBase from '@boilerplate/components/DialogBase';
import { Button } from '@mui/material';
import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ErrorMessage, confirmAccount } from '@/lib/oauth';
import PageLoading from '@/routes/PageLoading';

function OAuthConfirmAccountPage() {
  const { t } = useTranslation();
  const { confirmationCode } = useParams<{ confirmationCode: string }>();
  const [openDialog, setOpenDialog] = useState(false);
  const [basicTenantInfo, setBasicTenantInfo] = useState<{ name: string; frontendBaseUri: string } | null>();
  const [errorMsg, setErrorMsg] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!confirmationCode) {
      return;
    }

    confirmAccount(confirmationCode)
      .then((response) => {
        setBasicTenantInfo(response.data);
        setLoading(false);
        setOpenDialog(true);
      })
      .catch((error: AxiosError) => {
        setErrorMsg(error?.response?.data?.message);
      });
  }, [confirmationCode]);

  const handleRedirectToApp = () => {
    window.location.href = basicTenantInfo?.frontendBaseUri;
  };

  if (loading) {
    return <PageLoading />;
  }

  return (
    <>
      {errorMsg && <ErrorMessage message={t(errorMsg, 'auth:confirmAccount.invalidCode')} />}
      <DialogBase
        open={openDialog}
        id="confirmation-successful-dialog"
        title={t('auth:confirmAccount.title')}
        buttons={<Button onClick={handleRedirectToApp}>{t('oauth:backToApp', { appName: basicTenantInfo?.name })}</Button>}
        description={t('oauth:confirmAccount.success', { appName: basicTenantInfo?.name })}
      />
    </>
  );
}

export default OAuthConfirmAccountPage;
