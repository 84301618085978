import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';

type OptionalConfig = {
  origin: string;
};

type RequiredConfig = {
  publicKey: string;
};

export type Config = Partial<OptionalConfig> & RequiredConfig;
export type FullConfig = OptionalConfig & RequiredConfig;

export const defaultConfig = Object.freeze<OptionalConfig>({
  origin: 'https://cms.duodeka.com',
});

export class FlybaseBase {
  #config: FullConfig;

  constructor(config: FullConfig) {
    this.#config = config;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected makeRequest<T = any, R extends AxiosResponse<T> = AxiosResponse<T>, D = any>(
    path: string,
    config: AxiosRequestConfig<D> = {}
  ): Promise<R> {
    return axios.request({
      method: 'POST',
      ...config,
      url: new URL(path, this.#config.origin).toString(),
      headers: {
        ...config.headers,
        Authorization: `Bearer ${this.#config.publicKey}`,
      },
    });
  }
}
