import Flybase from '@boilerplate/lib/flybase';

import config from '@/config';

const flybase = new Flybase({
  publicKey: config.flybase.publicKey,
  origin: config.flybase.baseUrl,
});

export default flybase;
